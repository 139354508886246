import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/hr_va_guide_header.png");
const section_1 = require("../../../assets/img/blogs/hr_va_guide_image_1.png");
const section_2 = require("../../../assets/img/blogs/hr_va_guide_image_2.png");
const section_3 = require("../../../assets/img/blogs/hr_va_guide_image_3.png");
const section_4 = require("../../../assets/img/blogs/hr_va_guide_image_4.png");
const section_5 = require("../../../assets/img/blogs/hr_va_guide_image_5.png");
const section_6 = require("../../../assets/img/blogs/hr_va_guide_image_6.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="HR Virtual Assistant - A step-by-step guide to building your HR chatbot "
        description="An automated HR virtual assistant automates the most mundane and repetitive work processes for your HR team. Get Workativ to develop your HR chatbot. "
        keywords={[
          "virtual hr assistants",
          "ai virtual assistant for hr",
          "conversational virtual assistant ",
          "human resource virtual assistant",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt hr_va_guide_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                <h1
                  className={`mb-2 ${
                    isMobile
                      ? "font-page-header-home-blog"
                      : "font-page-header-home-blog"
                  }`}
                >
                  HR Virtual Assistant
                </h1>
                <h2
                  className={` ${
                    isMobile
                      ? "font-section-sub-header-blog-title"
                      : "font-section-sub-header-blog-title"
                  }`}
                >
                  A step-by-step guide to building <br />
                  your HR chatbot
                </h2>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is a human resource virtual assistant?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why do you need a virtual assistant for human resource
                  operations?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. What are practical use cases of virtual assistants for HR?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How virtual assistant for Human resources can drive
                  business benefits?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Can you build an AI virtual assistant for HR?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Build an HR virtual assistant with Workativ: a step-by-step
                  guide.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Why Workativ for your HR virtual assistant?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. Conclusion
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section9")}
                >
                  9. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                An{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  automated HR virtual assistant automates the most mundane and
                  repetitive work processes
                </a>{" "}
                and frees your HR team to look at more complicated issues to
                improve employee well-being and productivity at scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, are your HR functions perfect for being recognized as
                ex-driven HR? Consider it as employee experience-driven HR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Amidst the shifting employee expectations, human resource
                processes must align with employee objectives and give them
                opportunities to thrive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Driving employee experience seeks to deliver HR functions as and
                when needed. It is no shock that the ability to automate and
                streamline HR processes transforms employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to{" "}
                <a href="https://www.gartner.com/en/articles/8-learnings-from-gartner-s-hype-cycle-for-hr-transformation">
                  Gartner,
                </a>{" "}
                47% of HR leaders love to increase their HR technology budget,
                wherein AI becomes a top choice.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Gartner surveys further reported HR leaders are considering
                Generative AI chatbots for employee support functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Virtual HR assistants are a significant HR technology to advance
                the digital employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you are also considering a virtual HR chatbot or a Generative
                AI chatbot for HR functions, check out our step-by-step guide to
                building your virtual assistant.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is a human resource virtual assistant?’
              </h2>

              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                A virtual{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  human resource assistant
                </a>{" "}
                is a platform for automating and streamlining the most common
                employee HR inquiries and requests. The business-critical
                mission of a virtual assistant for HR is to maximize human
                resource efficiency and employee productivity. Workflow
                automation can be achieved by leveraging machine learning
                algorithms, natural language processing, and artificial
                intelligence.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is more digital than human support, where usual interactions
                happen between humans and an application or software program. As
                a result,
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A sophisticated{" "}
                <span className="font-section-normal-text-testimonials-medium">
                  HR virtual assistant deciphers text or voice commands and
                  offers meaningful and contextual automated responses.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s clear up much ambiguity around the virtual assistant
                ecosystem.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Virtual assistants can also feature digital platforms like
                  Siri, Alexa, or Cortana. The difference is they are not the
                  same as contract virtual personal assistants. Instead, these
                  AI-powered digital assistants respond to users’ commands to
                  perform actions such as giving a weather update, reminder
                  notifications, and so on.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium">
                    Digital virtual assistants for HR encompass conversational
                    AI capabilities,
                  </span>{" "}
                  meaning the application program can think, differentiate, and
                  analyze the intent of questions to offer automated responses
                  in a human-like manner. For example,{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/hr-chatbot-guide">
                    conversational AI can power chatbots or virtual agents
                  </a>{" "}
                  to act as automated virtual assistants for HR.
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR virtual assistants are remote personal support, not digital
                  or automated platforms.
                </li>
              </ul>
              <BlogCta
                ContentCta="Auto-resolve 60% of Employee Queries with a Virtual Assistant."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need a virtual assistant for human resource
                operations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee experience always matters. According to ServiceNow’s
                “The Employee Experience Imperative” Report,{" "}
                <a href="https://www.servicenow.com/company/media/press-room/what-workers-really-want-a-better-employee-experience.html">
                  44% of employees agreed they have easy access to HR
                  information at the workplace.
                </a>
                Unfortunately, the same number of participants express
                disagreement with the outlook.
              </p>
              <h3 className="font-section-sub-header-small">
                Lack of easy access to information
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees need vital information to get started on day one, but
                lacking access to human resource information could send a
                negative message about an organization’s culture and increase
                dissatisfaction and disengagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The gap between employee and HR interaction could emerge from a
                couple of reasons 一
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Your HR may be on leave
                </li>
                <li className="font-section-normal-text-testimonials">
                  Many smaller companies do not document their HR processes
                </li>
                <li className="font-section-normal-text-testimonials">
                  Major announcements or internal communications are not
                  recorded
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR processes are not current.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small">
                Asymmetrical HR/employee ratio
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                In addition to these challenges in the HR work process, the
                <span className="font-section-normal-text-testimonials-medium color-black">
                  average ratio of HR professionals per 100 employees is
                </span>
                concerning.
              </p>
              <img src={section_1} className="blog_image_top_bt" />
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  {" "}
                  Small companies can get control over employee/hr communication
                  improvement. However, some challenges include
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Huge workloads for the HR person to manage tasks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Their work processes are not organized and streamlined
                </li>
                <li className="font-section-normal-text-testimonials">
                  High wait time for HR information
                </li>
                <li className="font-section-normal-text-testimonials">
                  In many cases, they lack policy documentation, leading to
                  violation of workplace compliance
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Mid-sized companies tend to have adequate resources to manage
                  HR activities, but there are also some limitations,
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Lack of updated hiring practice in terms of employee placement
                  across remote or hybrid structure
                </li>
                <li className="font-section-normal-text-testimonials">
                  Lack of resources to solve real staff issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Weakened structure of training and upskilling policies
                </li>
                <li className="font-section-normal-text-testimonials">
                  Lack of information and analytics tools to define the future
                  practice
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Large companies have more resources to distribute the workload
                  as more people work to handle HR operations. However, the
                  downsides are
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  They tend to outsource several vital activities such as
                  recruiting, onboarding, etc.
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR/employee ratio declines, impacting empathy and connection
                </li>
                <li className="font-section-normal-text-testimonials">
                  Handing costs of HR activities increase
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The HR-employee ratio is essential, as the perfect ratio helps
                improve communications and collaborations. The ratio is not
                symmetrical across the industry, so HR challenges increase when
                there is a lack of real-time response or connectivity. As a
                result, employee dissatisfaction and attrition may become
                frequent HR issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Organizations can use a virtual assistant for human resources to
                minimize and improve HR operations. The automated conversational
                chatbot can provide organizations with real-time business use
                cases.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are practical use cases of virtual assistants for HR?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With an HR virtual assistant, you can implement use cases for
                human resource service needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are important use cases—
              </p>
              <h3 className="font-section-sub-header-small-home">
                Welcome a new hire
              </h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="a virtual assistant for HR to help welcome a new hire"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Your new hire feels valued when they receive a warm welcome from
                the team they join in an organization. However, this is only
                sometimes the case, mainly when operating remotely. More often
                than not, this significant piece of HR work goes unnoticed. The
                entire introduction session or ‘know your workplace’ may not be
                included on the first day at the office.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-2">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  {" "}
                  An HR virtual assistant can help you automate the welcome
                  process intuitively,
                </a>{" "}
                eliminating the risk of adding to employee disengagement and
                attrition. You stay ahead of time by customizing your workflows.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/workflow-automation-platform/integrations/bamboohr">
                    Trigger workflows as soon as a new hire is added to the HR
                    systems like BambooHR
                  </a>{" "}
                  or{" "}
                  <span className="font-section-normal-text-testimonials-medium">
                    Zoho People
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send a welcome email and a message in the business comms
                  channel like
                  <span className="font-section-normal-text-testimonials-medium">
                    Teams or Slack via integration through the automated virtual
                    HR assistant or chatbot.
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send an invitation to the channel for an introduction round
                  with the specific team.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  Establish communication with fellow members and invite them to
                  the introduction session.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Manage onboarding
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                An elevated onboarding experience has a lasting impact on
                employees and helps with retention. As BambooHR surveyed,
                <a href="https://www.bamboohr.com/blog/10-powerful-onboarding-activities-to-try">
                  employees are 29x more likely to feel satisfied with an
                  organized onboarding process than those with subpar
                </a>
                techniques.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                When an employee joins your team, it is a hectic day for your HR
                to make her settle perfectly at her desk. The first day for your
                new hire is highly significant as the first-day experience gets
                etched in her memory. With the traditional work process,
                arranging for an onboarding buddy, documentation, or team
                introduction needs to be more organized and addressed. You can
                easily streamline these work processes using
                <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                  an automated virtual HR assistant for onboarding.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="An automated HR virtual assistant for onboarding "
              />
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Create workflows inside your HRSM systems that trigger
                  messages for processes, including
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Ask your new hire to supply information to complete the
                  profile in internal systems via chatbot messages inside Slack,
                  Teams, or email.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule a meeting with an introduction buddy or one of the
                  key stakeholders.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send credential links for access to internal systems, such as
                  preliminary apps.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  Communicate to enable her to complete the agreement document
                  with the necessary actions
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Automate the hiring process
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                The process of hiring is always labor-intensive and
                nerve-wracking when the methods are manual. Say your HR person
                needs to oversee the task. It involves sorting the profiles
                based on skills and experience that match the job requirement.
                The problem arises when you need to establish communications
                with candidates back and forth. It is repetitive and
                labor-intensive for your team.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                {" "}
                Surprisingly, you can automate all hiring processes from
                initiation to end with an{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  AI-powered chatbot using app workflow automation end-to-end.
                </a>{" "}
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Customize your workflow automation to segregate profiles based
                on skills and experience
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Trigger emails to provide feedback for applications that don’t
                  match
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule an interview with qualified candidates
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule an assessment test
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule final round of interviews
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  A conversational AI chatbot builder from Workativ
                </a>{" "}
                allows you to customize your unique needs and set rules for your
                app workflow automation.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Handle leave management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Streamlining payroll depends on effectively managed leave
                policies. If you handle employee leave management manually,
                monitoring leaves and calculating the balances is tough.
                Sometimes, ill management can raise employee disputes.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Avoid the growing issues with leave management by providing
                <a href="https://workativ.com/conversational-ai-platform/blog/hris-employee-self-service-guide">
                  self-service capabilities.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Workativ helps organizations{" "}
                <a href="https://workativ.com/use-cases/leave-management-automation">
                  simplify PTO inquiries and requests with an automated
                  intelligent, and intuitive virtual HR assistant.
                </a>
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="An automated HR virtual assistant for leave management"
              />
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create workflows and conversation templates for the HR chatbot
                  inside your communication channels.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow your employees to view real-time leave status.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Choose the options and apply for leave.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Email the confirmation details to the specified HR teams and
                  the employee.
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                When you use the Workativ HR chatbot, you can easily create your
                workflows by following the steps in the image below.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Leave management automation with an HR virtual assistant "
              />
              <h3 className="font-section-sub-header-small-home">
                Provide consistent workplace information
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say your team sends out an email seeking several workplace
                policies related to PTOs, bonuses, and tax calculations. It can
                be challenging for an HR person to reply consistently to
                queries. To avoid inconsistent responses and vague perceptions
                about workplace policy, knowledge is an effective way to make
                your employees feel connected and accelerate productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                {" "}
                If you{" "}
                <a href="https://workativ.com/conversational-ai-platform/dropbox-chatbot">
                  create your knowledge base content on third-party platforms
                  like Dropbox or Google Drive, an HR chatbot or virtual
                  assistant for human resources can provide the integration
                  flexibility
                </a>{" "}
                and pull the information directly into your chatbot.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="a virtual hr assistant or chatbot helps build a knowledgebase"
              />
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create workflows and templates for your HR chatbot
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow your users to ask a question
                </li>
                <li className="font-section-normal-text-testimonials">
                  When a keyword is matched, an appropriate link or folder to
                  the document will appear.
                </li>
                <li className="font-section-normal-text-testimonials">
                  If a match is not found, enable the creation of a ticket from
                  within the chatbot in your business comms channels.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Handoffs to the subject matter expert and solve the issue
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How virtual assistant for Human resources can drive business
                benefits?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you gain the ability to configure your use cases as per
                business needs, the business benefits are immense.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Encourage bottom-line revenue growth
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your annual operating cost, surprisingly, can come down as you
                look to automate 80% of repetitive HR activities.
              </p>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 mb-3 float-left w-100">
                <li className="color-blog-main-ul">
                  When an HR team has to deal with a growing number of tickets,
                  the ticket handling costs rise.
                  <a href="https://workativ.com/conversational-ai-platform/cost-reduction">
                    {" "}
                    HR workflow automation brings the cost per ticket to less
                    than $2 from $20.
                  </a>
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  You can rapidly bring down advertising costs for recruiting as
                  automation improves employee engagement and helps with
                  retention
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Reduce costs for advertising
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce costs that you would otherwise require to refill the
                  role
                </li>
                <li className="font-section-normal-text-testimonials">
                  Maximize employee acquisition costs by providing elevated
                  onboarding experience
                </li>
                <li className="font-section-normal-text-testimonials">
                  Save on internal hr budget for efforts to support hiring
                  process
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Bring down email and call volumes
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With a{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-service-management-guide">
                  virtual assistant for HR delivering self-service capabilities
                </a>{" "}
                and workflow automation, it is fewer workloads for a human
                resource team.
              </p>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 mb-3 float-left w-100">
                <li className="color-blog-main-ul">
                  You gain instant auto-resolution flexibility
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Reduce the need to connect with emails and calls
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5  font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium">
                  Get 90% first contact resolution
                </li>
                <li className="font-section-normal-text-testimonials-medium">
                  Reduce MTTR by 5X
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Reduce business phone costs
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Employees can ask more questions
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                It is convenient to ask more questions when questions are easily
                accessible through self-service automation. Usually, employees
                are hesitant when they need to ask a question. They occasionally
                ask instead of seeking the information directly from the right
                person.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/increase-employee-productivity">
                  A human resource virtual assistant is a straightforward way to
                  address the issue largely.
                </a>{" "}
                Employees can raise questions, get their queries solved, and
                feel satisfied. The advantage is your employees are more
                productive and engaged. On the other way around, your HR team
                also leverages elevated experience and removes the extra load.
                Self-driven features help employees in many ways,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Direct access to expense information
                </li>
                <li className="font-section-normal-text-testimonials">
                  Easy access to maternity leave data
                </li>
                <li className="font-section-normal-text-testimonials">
                  Access to extended coverage through knowledge base items
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                More streamlined HR operations
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  HR workflow automation
                </a>{" "}
                efficiently reduces workloads for the HR teams. As a result, HR
                processes become more streamlined and less error-prone. It means
                your HR team can increase efficiency by minimizing the volume of
                repetitive tasks through automation, allowing them to improve
                their efficiency for tasks requiring more attention and effort.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonial">
                  Your HR team is flexible to handle critical tasks (i.e.,
                  appraisal management, analyzing performance data)
                </li>
                <li className="font-section-normal-text-testimonials">
                  New roles and responsibilities can become easy to handle, for
                  example, upskilling and training
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                HR policy maintenance and compliance
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A HR chatbot can easily allow fetching massive data from the
                system to simplify the monitoring capabilities across the
                workplace culture and processes. It lets your HR team stay ahead
                of the threat by gaining a clear view of compliance regulations.
                Advanced data analytics is an additional advantage to staying
                compliant and avoiding non-compliance penalties and financial
                losses.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Ease of adoption for users
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                An HR virtual assistant with conversational AI capabilities
                provides an intuitive platform in the form of a workplace
                chatbot. The advantage ramps up{" "}
                <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                  when it easily syncs with familiar business communications
                  channels such as Teams and Slack.
                </a>{" "}
                It means it is easy for any user as they already know the
                platform and its usability. Unlike a new software suite or tool,
                it is easy to adopt and adapt to the platform and get started
                instantly.
              </p>
              <h3 className="font-section-sub-header-small-home">
                No extra training and extra resources for the purpose!
              </h3>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Can you build an AI virtual assistant for HR?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Building HR chatbots can be intimidating, especially when you
                plan to build them in-house with your software development team.
                The cost of building them may be a major blocker.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A low-code or no-code platform is a convenient way to build your
                HR bot. A SaaS-based chatbot builder gives you tools like an NLP
                engine to leverage intent and entity detection, disambiguation,
                context switching, and other useful features to achieve complete
                efficiency for your HR objectives.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Besides, a no-code platform is easy to use. Implementing
                workflows and automating HR processes does not require a steep
                learning curve. Using a plug-and-play interface, you can
                implement automated workflows and streamline mundane operations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                All you need to do is pay subscription charges. Many service
                providers have custom options based on your specific needs.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If the hassle-free option works best, build your HR virtual
                assistant chatbot with a cloud-based SaaS chatbot builder like{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Build an HR virtual assistant with Workativ: a step-by-step
                guide.
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Workativ conversational AI chatbot builder is simple to use. No
                one needs technical knowledge to get started. Let’s see how you
                can build your HR bot with Workativ.
              </p>
              <h3 className="font-section-sub-header-small">
                Signup with Workativ
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                If you want to test and trial how your HR bot works, create a
                free account with Workativ. Sign up and provide all the
                necessary details to access the development environment.
              </p>
              <h3 className="font-section-sub-header-small">
                Choose an HR bot template.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You have two options for building your preferred HR chatbot or
                HR virtual assistant.
              </p>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li>i. Knowledge AI Bot</li>
                <li>ii. Conversational Bot</li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                Knowledge AI bot provides ChatGPT-like capabilities for HR
                process automation, while conversational AI provides automation
                and facilitates AI conversations. Let’s start with a
                conversational AI bot.
              </p>
              <h3 className="font-section-sub-header-small">
                Get started with Conversational AI
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You land on a screen full of pre-built bot templates as you
                click it.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Select a pre-built HR bot from these options. Click it to
                download it.
              </p>
              <h3 className="font-section-sub-header-small">
                Find pre-built conversation templates
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can choose any conversation template specific to your HR
                needs. Also, take note of the ‘warning sign’. It means you need
                to connect the conversation templates to HR workflows.
              </p>
              <h3 className="font-section-sub-header-small">
                Build conversation workflows
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Let’s say you want to create a conversation flow for ‘APPLY
                LEAVE’.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click it, and you will be directed to several critical steps to
                build your conversation flows.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you create a conversation flow for{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  ‘APPLY LEAVE’,
                </span>{" "}
                you can find flows such as{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  LEAVE START DATE,
                </span>{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  LEAVE END DATE,
                </span>{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  CONFIRM DATE,
                </span>{" "}
                and so on.
              </p>
              <h3 className="font-section-sub-header-small">
                Build workflow automation{" "}
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                After you set your conversation flows for ‘APPLY LEAVE,’ you
                need to enable automation.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click the ‘Call Automation’ card. It will direct you to a window
                where you can create an app workflow.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you have no workflow created, you must build one. Click on
                ‘Go to Workflow Builder’.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, click ‘Create App Workflow’ or the icon of “Automation” on
                the left-hand-side panel.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you click ‘create workflow automation,’ you can find a window
                to name your workflow under the draft section.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can download it from the marketplace. But before you do so,
                verify your email address.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can search for your preferred application from the search
                bar or fetch it from the dropdown list.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Choose BambooHR. You can find a variety of actions to implement
                conversation workflows.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                For example, let’s select ‘Time Off’.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Once you click it, you are directed to a page to download your
                BambooHR conversation automation flow.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Select YES to download your conversation, which you can find in
                the draft section.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, click OKAY.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, under the draft section, you can find your TIME OFF
                conversation template.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The warning sign indicates that no connection has been set up.
                You need to set it up.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click on No Trigger. After that, you can find a window which
                asks you to edit your app workflow so that users can receive
                pre-populated triggers from chatbots or conditions as you set
                them for ongoing conversations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click on Save to proceed further.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, click on Assign TimeOff Policy. Fill details in the edit
                action card and connect account.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can see that all fields are provided with specific details
                beforehand. Click on Save.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It's time to activate the workflow. Click on the button on
                extreme top right corner.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click Yes to activate the workflow.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, let’s go back to the HR bot. It’s in the bot section. Find
                your dialog.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Find your conversation template ‘Apply Leave’ and click on it to
                find conversation flow for this. .
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, select call automation dialog.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Fill all the details collected from the user and click save.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you return to your chat dialog, you will find no warning
                signs, and your dialog is ready to be used.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To test whether your HR chatbot works properly, click TRY ME at
                the bottom corner.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Provide details as your HR chatbot asks you to see the
                effectiveness of your chatbot development.
              </p>
              <h3 className="font-section-sub-header-small">
                Publish your HR chatbot{" "}
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Once everything works all right, you can publish your bot to
                your favorite channel.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It can be MS Teams, Slack, or Web Widget.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To learn more about how you can build your HR virtual assistant,
                watch the demo{" "}
                <a href="https://www.loom.com/share/69651577ab9c44889004b01f1ea875e3?sid=4b1338ed-55e4-4064-87a7-94b40787ac97">
                  here.
                </a>
              </p>
            </div>

            <NocodeWrapper />
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Why Workativ for your HR virtual assistant?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ provides users immense flexibility to leverage the
                business benefits by enabling them to build an easy yet powerful
                virtual assistant for human resource teams.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Omni channel accessibility
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ can easily fit Teams, Slack, or Web architecture to
                deliver omnichannel convenience to your users. So, it is easy
                and fast for your users to adopt and use the chatbot in no time.
              </p>

              <h3 className="font-section-sub-header-small-home">
                NLP and AI algorithms
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Get extended customization flexibility with a chatbot builder
                that allows you to create simple to complex dialogs and
                conversations. Built on top of the NLP engine, the bot
                simplifies user intent and understanding.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Drag-and-drop intuitive interface
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                The no-code platform is a simple drag-and-drop interface. All
                you need is sign up, choose your templates, integrate with apps,
                and create app workflows.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Easy to deploy
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ offers multiple platforms to create your virtual HR
                assistant. Choose from three of the options,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonial">
                  App integration
                </li>
                <li className="font-section-normal-text-testimonials">
                  On-prem connect
                </li>
                <li className="font-section-normal-text-testimonials">
                  Marketplace download
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A virtual assistant for HR is essentially a powerful tool as the
                work culture evolves massively everywhere. Human resources need
                automation to tackle uncertain situations and stay resilient. As
                a result, your HR team will become more empowered to minimize
                workplace threats such as attrition, rising costs, and churn.
                Furthermore, a virtual HR assistant can help fetch AI-based
                analytics to gauge trends and prepare for uncertainties. In
                addition, AI innovations like generative AI and the evolution of
                ChatGPT offer more ways to reshape your workplace ecosystem and
                enhance employee experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To stay compliant and bring a change to your traditional HR
                model, you can evaluate your current situation and consider the
                benefits that a virtual assistant for HR offers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                {" "}
                Learn more about how Workativ helps you automate repetitive work
                processes by connecting with our expert.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What can you automate with an HR virtual assistant?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR virtual chatbot can automate various HR processes such as,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Onboarding
                </li>
                <li className="font-section-normal-text-testimonials">Leave</li>
                <li className="font-section-normal-text-testimonials">
                  Expense management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Scheduling appointments
                </li>
                <li className="font-section-normal-text-testimonials">
                  Answering FAQs etc
                </li>
              </ul>
              <h3 className="font-section-sub-header-small">
                2. How can you benefit from an HR virtual assistant?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can automate and streamline routine tasks for your employees
                and improve their efficiency and productivity by enabling them
                to auto-resolve their problems through self-service and
                enhancing access to information.
              </p>

              <h3 className="font-section-sub-header-small">
                3. How can you ensure data security and privacy with your HR
                chatbots?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Data privacy and security are paramount to making optimal use of
                AI-powered chatbots. While you build your chatbot, ensure you
                use data free from misinformation or bias. With that, you can
                also ensure using a chatbot builder that provides relevant data
                protection regulations and offers robust security measures. It's
                essential to protect sensitive employee information.
              </p>

              <h3 className="font-section-sub-header-small">
                4. Is it a massive effort to build your HR virtual assistant?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In-house chatbot development is always a headache. But, if you
                choose to leverage a no-code platform with a robust chatbot
                builder framework, you can build it seamlessly without having
                too much technical knowledge. Workativ provides a robust
                platform that enables you to create an HR chatbot. You can
                follow this{" "}
                <a href="https://www.loom.com/share/69651577ab9c44889004b01f1ea875e3?sid=4b1338ed-55e4-4064-87a7-94b40787ac97">
                  tutorial
                </a>{" "}
                to build your first free chatbot for HR processes.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}

function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is a human resource virtual assistant?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why do you need a virtual assistant for human resource
                    operations?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. What are practical use cases of virtual assistants for
                    HR?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How virtual assistant for Human resources can drive
                    business benefits?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Can you build an AI virtual assistant for HR?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Build an HR virtual assistant with Workativ: a
                    step-by-step guide.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Why Workativ for your HR virtual assistant?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. Conclusion
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section9")}
                  >
                    9. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                An{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  automated HR virtual assistant automates the most mundane and
                  repetitive work processes
                </a>{" "}
                and frees your HR team to look at more complicated issues to
                improve employee well-being and productivity at scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, are your HR functions perfect for being recognized as
                ex-driven HR? Consider it as employee experience-driven HR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Amidst the shifting employee expectations, human resource
                processes must align with employee objectives and give them
                opportunities to thrive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Driving employee experience seeks to deliver HR functions as and
                when needed. It is no shock that the ability to automate and
                streamline HR processes transforms employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to{" "}
                <a href="https://www.gartner.com/en/articles/8-learnings-from-gartner-s-hype-cycle-for-hr-transformation">
                  Gartner,
                </a>{" "}
                47% of HR leaders love to increase their HR technology budget,
                wherein AI becomes a top choice.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Gartner surveys further reported HR leaders are considering
                Generative AI chatbots for employee support functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Virtual HR assistants are a significant HR technology to advance
                the digital employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you are also considering a virtual HR chatbot or a Generative
                AI chatbot for HR functions, check out our step-by-step guide to
                building your virtual assistant.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is a human resource virtual assistant?’
              </h2>

              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                A virtual{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  human resource assistant
                </a>{" "}
                is a platform for automating and streamlining the most common
                employee HR inquiries and requests. The business-critical
                mission of a virtual assistant for HR is to maximize human
                resource efficiency and employee productivity. Workflow
                automation can be achieved by leveraging machine learning
                algorithms, natural language processing, and artificial
                intelligence.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is more digital than human support, where usual interactions
                happen between humans and an application or software program. As
                a result,
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A sophisticated{" "}
                <span className="font-section-normal-text-testimonials-medium">
                  HR virtual assistant deciphers text or voice commands and
                  offers meaningful and contextual automated responses.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s clear up much ambiguity around the virtual assistant
                ecosystem.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Virtual assistants can also feature digital platforms like
                  Siri, Alexa, or Cortana. The difference is they are not the
                  same as contract virtual personal assistants. Instead, these
                  AI-powered digital assistants respond to users’ commands to
                  perform actions such as giving a weather update, reminder
                  notifications, and so on.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium">
                    Digital virtual assistants for HR encompass conversational
                    AI capabilities,
                  </span>{" "}
                  meaning the application program can think, differentiate, and
                  analyze the intent of questions to offer automated responses
                  in a human-like manner. For example,{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/hr-chatbot-guide">
                    conversational AI can power chatbots or virtual agents
                  </a>{" "}
                  to act as automated virtual assistants for HR.
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR virtual assistants are remote personal support, not digital
                  or automated platforms.
                </li>
              </ul>
              <BlogCta
                ContentCta="Auto-resolve 60% of Employee Queries with a Virtual Assistant."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why do you need a virtual assistant for human resource
                operations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee experience always matters. According to ServiceNow’s
                “The Employee Experience Imperative” Report,{" "}
                <a href="https://www.servicenow.com/company/media/press-room/what-workers-really-want-a-better-employee-experience.html">
                  44% of employees agreed they have easy access to HR
                  information at the workplace.
                </a>
                Unfortunately, the same number of participants express
                disagreement with the outlook.
              </p>
              <h3 className="font-section-sub-header-small">
                Lack of easy access to information
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees need vital information to get started on day one, but
                lacking access to human resource information could send a
                negative message about an organization’s culture and increase
                dissatisfaction and disengagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The gap between employee and HR interaction could emerge from a
                couple of reasons 一
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Your HR may be on leave
                </li>
                <li className="font-section-normal-text-testimonials">
                  Many smaller companies do not document their HR processes
                </li>
                <li className="font-section-normal-text-testimonials">
                  Major announcements or internal communications are not
                  recorded
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR processes are not current.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small">
                Asymmetrical HR/employee ratio
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                In addition to these challenges in the HR work process, the
                <span className="font-section-normal-text-testimonials-medium color-black">
                  average ratio of HR professionals per 100 employees is
                </span>
                concerning.
              </p>
              <img src={section_1} className="blog_image_top_bt" />
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  {" "}
                  Small companies can get control over employee/hr communication
                  improvement. However, some challenges include
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Huge workloads for the HR person to manage tasks
                </li>
                <li className="font-section-normal-text-testimonials">
                  Their work processes are not organized and streamlined
                </li>
                <li className="font-section-normal-text-testimonials">
                  High wait time for HR information
                </li>
                <li className="font-section-normal-text-testimonials">
                  In many cases, they lack policy documentation, leading to
                  violation of workplace compliance
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Mid-sized companies tend to have adequate resources to manage
                  HR activities, but there are also some limitations,
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Lack of updated hiring practice in terms of employee placement
                  across remote or hybrid structure
                </li>
                <li className="font-section-normal-text-testimonials">
                  Lack of resources to solve real staff issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Weakened structure of training and upskilling policies
                </li>
                <li className="font-section-normal-text-testimonials">
                  Lack of information and analytics tools to define the future
                  practice
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Large companies have more resources to distribute the workload
                  as more people work to handle HR operations. However, the
                  downsides are
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  They tend to outsource several vital activities such as
                  recruiting, onboarding, etc.
                </li>
                <li className="font-section-normal-text-testimonials">
                  HR/employee ratio declines, impacting empathy and connection
                </li>
                <li className="font-section-normal-text-testimonials">
                  Handing costs of HR activities increase
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The HR-employee ratio is essential, as the perfect ratio helps
                improve communications and collaborations. The ratio is not
                symmetrical across the industry, so HR challenges increase when
                there is a lack of real-time response or connectivity. As a
                result, employee dissatisfaction and attrition may become
                frequent HR issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Organizations can use a virtual assistant for human resources to
                minimize and improve HR operations. The automated conversational
                chatbot can provide organizations with real-time business use
                cases.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are practical use cases of virtual assistants for HR?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With an HR virtual assistant, you can implement use cases for
                human resource service needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are important use cases—
              </p>
              <h3 className="font-section-sub-header-small-home">
                Welcome a new hire
              </h3>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="a virtual assistant for HR to help welcome a new hire"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Your new hire feels valued when they receive a warm welcome from
                the team they join in an organization. However, this is only
                sometimes the case, mainly when operating remotely. More often
                than not, this significant piece of HR work goes unnoticed. The
                entire introduction session or ‘know your workplace’ may not be
                included on the first day at the office.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-2">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  {" "}
                  An HR virtual assistant can help you automate the welcome
                  process intuitively,
                </a>{" "}
                eliminating the risk of adding to employee disengagement and
                attrition. You stay ahead of time by customizing your workflows.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/workflow-automation-platform/integrations/bamboohr">
                    Trigger workflows as soon as a new hire is added to the HR
                    systems like BambooHR
                  </a>{" "}
                  or{" "}
                  <span className="font-section-normal-text-testimonials-medium">
                    Zoho People
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send a welcome email and a message in the business comms
                  channel like
                  <span className="font-section-normal-text-testimonials-medium">
                    Teams or Slack via integration through the automated virtual
                    HR assistant or chatbot.
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send an invitation to the channel for an introduction round
                  with the specific team.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  Establish communication with fellow members and invite them to
                  the introduction session.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Manage onboarding
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                An elevated onboarding experience has a lasting impact on
                employees and helps with retention. As BambooHR surveyed,
                <a href="https://www.bamboohr.com/blog/10-powerful-onboarding-activities-to-try">
                  employees are 29x more likely to feel satisfied with an
                  organized onboarding process than those with subpar
                </a>
                techniques.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                When an employee joins your team, it is a hectic day for your HR
                to make her settle perfectly at her desk. The first day for your
                new hire is highly significant as the first-day experience gets
                etched in her memory. With the traditional work process,
                arranging for an onboarding buddy, documentation, or team
                introduction needs to be more organized and addressed. You can
                easily streamline these work processes using
                <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                  an automated virtual HR assistant for onboarding.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="An automated HR virtual assistant for onboarding "
              />
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Create workflows inside your HRSM systems that trigger
                  messages for processes, including
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Ask your new hire to supply information to complete the
                  profile in internal systems via chatbot messages inside Slack,
                  Teams, or email.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule a meeting with an introduction buddy or one of the
                  key stakeholders.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send credential links for access to internal systems, such as
                  preliminary apps.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  Communicate to enable her to complete the agreement document
                  with the necessary actions
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Automate the hiring process
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                The process of hiring is always labor-intensive and
                nerve-wracking when the methods are manual. Say your HR person
                needs to oversee the task. It involves sorting the profiles
                based on skills and experience that match the job requirement.
                The problem arises when you need to establish communications
                with candidates back and forth. It is repetitive and
                labor-intensive for your team.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                {" "}
                Surprisingly, you can automate all hiring processes from
                initiation to end with an{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  AI-powered chatbot using app workflow automation end-to-end.
                </a>{" "}
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Customize your workflow automation to segregate profiles based
                on skills and experience
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Trigger emails to provide feedback for applications that don’t
                  match
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule an interview with qualified candidates
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule an assessment test
                </li>
                <li className="font-section-normal-text-testimonials">
                  Schedule final round of interviews
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  A conversational AI chatbot builder from Workativ
                </a>{" "}
                allows you to customize your unique needs and set rules for your
                app workflow automation.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Handle leave management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Streamlining payroll depends on effectively managed leave
                policies. If you handle employee leave management manually,
                monitoring leaves and calculating the balances is tough.
                Sometimes, ill management can raise employee disputes.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Avoid the growing issues with leave management by providing
                <a href="https://workativ.com/conversational-ai-platform/blog/hris-employee-self-service-guide">
                  self-service capabilities.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Workativ helps organizations{" "}
                <a href="https://workativ.com/use-cases/leave-management-automation">
                  simplify PTO inquiries and requests with an automated
                  intelligent, and intuitive virtual HR assistant.
                </a>
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="An automated HR virtual assistant for leave management"
              />
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create workflows and conversation templates for the HR chatbot
                  inside your communication channels.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow your employees to view real-time leave status.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Choose the options and apply for leave.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Email the confirmation details to the specified HR teams and
                  the employee.
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                When you use the Workativ HR chatbot, you can easily create your
                workflows by following the steps in the image below.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Leave management automation with an HR virtual assistant "
              />
              <h3 className="font-section-sub-header-small-home">
                Provide consistent workplace information
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say your team sends out an email seeking several workplace
                policies related to PTOs, bonuses, and tax calculations. It can
                be challenging for an HR person to reply consistently to
                queries. To avoid inconsistent responses and vague perceptions
                about workplace policy, knowledge is an effective way to make
                your employees feel connected and accelerate productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                {" "}
                If you{" "}
                <a href="https://workativ.com/conversational-ai-platform/dropbox-chatbot">
                  create your knowledge base content on third-party platforms
                  like Dropbox or Google Drive, an HR chatbot or virtual
                  assistant for human resources can provide the integration
                  flexibility
                </a>{" "}
                and pull the information directly into your chatbot.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="a virtual hr assistant or chatbot helps build a knowledgebase"
              />
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create workflows and templates for your HR chatbot
                </li>
                <li className="font-section-normal-text-testimonials">
                  Allow your users to ask a question
                </li>
                <li className="font-section-normal-text-testimonials">
                  When a keyword is matched, an appropriate link or folder to
                  the document will appear.
                </li>
                <li className="font-section-normal-text-testimonials">
                  If a match is not found, enable the creation of a ticket from
                  within the chatbot in your business comms channels.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Handoffs to the subject matter expert and solve the issue
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How virtual assistant for Human resources can drive business
                benefits?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you gain the ability to configure your use cases as per
                business needs, the business benefits are immense.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Encourage bottom-line revenue growth
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your annual operating cost, surprisingly, can come down as you
                look to automate 80% of repetitive HR activities.
              </p>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 mb-3 float-left w-100">
                <li className="color-blog-main-ul">
                  When an HR team has to deal with a growing number of tickets,
                  the ticket handling costs rise.
                  <a href="https://workativ.com/conversational-ai-platform/cost-reduction">
                    {" "}
                    HR workflow automation brings the cost per ticket to less
                    than $2 from $20.
                  </a>
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  You can rapidly bring down advertising costs for recruiting as
                  automation improves employee engagement and helps with
                  retention
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Reduce costs for advertising
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce costs that you would otherwise require to refill the
                  role
                </li>
                <li className="font-section-normal-text-testimonials">
                  Maximize employee acquisition costs by providing elevated
                  onboarding experience
                </li>
                <li className="font-section-normal-text-testimonials">
                  Save on internal hr budget for efforts to support hiring
                  process
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Bring down email and call volumes
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With a{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-service-management-guide">
                  virtual assistant for HR delivering self-service capabilities
                </a>{" "}
                and workflow automation, it is fewer workloads for a human
                resource team.
              </p>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 mb-3 float-left w-100">
                <li className="color-blog-main-ul">
                  You gain instant auto-resolution flexibility
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Reduce the need to connect with emails and calls
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-5  font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium">
                  Get 90% first contact resolution
                </li>
                <li className="font-section-normal-text-testimonials-medium">
                  Reduce MTTR by 5X
                </li>
              </ul>
              <ul className="font-section-normal-text-testimonials p-0 pl-3 float-left w-100">
                <li className="color-blog-main-ul">
                  Reduce business phone costs
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Employees can ask more questions
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                It is convenient to ask more questions when questions are easily
                accessible through self-service automation. Usually, employees
                are hesitant when they need to ask a question. They occasionally
                ask instead of seeking the information directly from the right
                person.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/increase-employee-productivity">
                  A human resource virtual assistant is a straightforward way to
                  address the issue largely.
                </a>{" "}
                Employees can raise questions, get their queries solved, and
                feel satisfied. The advantage is your employees are more
                productive and engaged. On the other way around, your HR team
                also leverages elevated experience and removes the extra load.
                Self-driven features help employees in many ways,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Direct access to expense information
                </li>
                <li className="font-section-normal-text-testimonials">
                  Easy access to maternity leave data
                </li>
                <li className="font-section-normal-text-testimonials">
                  Access to extended coverage through knowledge base items
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                More streamlined HR operations
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  HR workflow automation
                </a>{" "}
                efficiently reduces workloads for the HR teams. As a result, HR
                processes become more streamlined and less error-prone. It means
                your HR team can increase efficiency by minimizing the volume of
                repetitive tasks through automation, allowing them to improve
                their efficiency for tasks requiring more attention and effort.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonial">
                  Your HR team is flexible to handle critical tasks (i.e.,
                  appraisal management, analyzing performance data)
                </li>
                <li className="font-section-normal-text-testimonials">
                  New roles and responsibilities can become easy to handle, for
                  example, upskilling and training
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                HR policy maintenance and compliance
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A HR chatbot can easily allow fetching massive data from the
                system to simplify the monitoring capabilities across the
                workplace culture and processes. It lets your HR team stay ahead
                of the threat by gaining a clear view of compliance regulations.
                Advanced data analytics is an additional advantage to staying
                compliant and avoiding non-compliance penalties and financial
                losses.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Ease of adoption for users
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                An HR virtual assistant with conversational AI capabilities
                provides an intuitive platform in the form of a workplace
                chatbot. The advantage ramps up{" "}
                <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                  when it easily syncs with familiar business communications
                  channels such as Teams and Slack.
                </a>{" "}
                It means it is easy for any user as they already know the
                platform and its usability. Unlike a new software suite or tool,
                it is easy to adopt and adapt to the platform and get started
                instantly.
              </p>
              <h3 className="font-section-sub-header-small-home">
                No extra training and extra resources for the purpose!
              </h3>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Can you build an AI virtual assistant for HR?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Building HR chatbots can be intimidating, especially when you
                plan to build them in-house with your software development team.
                The cost of building them may be a major blocker.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                A low-code or no-code platform is a convenient way to build your
                HR bot. A SaaS-based chatbot builder gives you tools like an NLP
                engine to leverage intent and entity detection, disambiguation,
                context switching, and other useful features to achieve complete
                efficiency for your HR objectives.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Besides, a no-code platform is easy to use. Implementing
                workflows and automating HR processes does not require a steep
                learning curve. Using a plug-and-play interface, you can
                implement automated workflows and streamline mundane operations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                All you need to do is pay subscription charges. Many service
                providers have custom options based on your specific needs.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If the hassle-free option works best, build your HR virtual
                assistant chatbot with a cloud-based SaaS chatbot builder like{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Build an HR virtual assistant with Workativ: a step-by-step
                guide.
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Workativ conversational AI chatbot builder is simple to use. No
                one needs technical knowledge to get started. Let’s see how you
                can build your HR bot with Workativ.
              </p>
              <h3 className="font-section-sub-header-small">
                Signup with Workativ
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                If you want to test and trial how your HR bot works, create a
                free account with Workativ. Sign up and provide all the
                necessary details to access the development environment.
              </p>
              <h3 className="font-section-sub-header-small">
                Choose an HR bot template.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You have two options for building your preferred HR chatbot or
                HR virtual assistant.
              </p>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials list-style-type-none">
                <li>i. Knowledge AI Bot</li>
                <li>ii. Conversational Bot</li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                Knowledge AI bot provides ChatGPT-like capabilities for HR
                process automation, while conversational AI provides automation
                and facilitates AI conversations. Let’s start with a
                conversational AI bot.
              </p>
              <h3 className="font-section-sub-header-small">
                Get started with Conversational AI
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You land on a screen full of pre-built bot templates as you
                click it.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Select a pre-built HR bot from these options. Click it to
                download it.
              </p>
              <h3 className="font-section-sub-header-small">
                Find pre-built conversation templates
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can choose any conversation template specific to your HR
                needs. Also, take note of the ‘warning sign’. It means you need
                to connect the conversation templates to HR workflows.
              </p>
              <h3 className="font-section-sub-header-small">
                Build conversation workflows
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Let’s say you want to create a conversation flow for ‘APPLY
                LEAVE’.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click it, and you will be directed to several critical steps to
                build your conversation flows.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you create a conversation flow for{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  ‘APPLY LEAVE’,
                </span>{" "}
                you can find flows such as{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  LEAVE START DATE,
                </span>{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  LEAVE END DATE,
                </span>{" "}
                <span className="font-section-normal-text-testimonial-medium color-black">
                  CONFIRM DATE,
                </span>{" "}
                and so on.
              </p>
              <h3 className="font-section-sub-header-small">
                Build workflow automation{" "}
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                After you set your conversation flows for ‘APPLY LEAVE,’ you
                need to enable automation.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click the ‘Call Automation’ card. It will direct you to a window
                where you can create an app workflow.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you have no workflow created, you must build one. Click on
                ‘Go to Workflow Builder’.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, click ‘Create App Workflow’ or the icon of “Automation” on
                the left-hand-side panel.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you click ‘create workflow automation,’ you can find a window
                to name your workflow under the draft section.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can download it from the marketplace. But before you do so,
                verify your email address.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can search for your preferred application from the search
                bar or fetch it from the dropdown list.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Choose BambooHR. You can find a variety of actions to implement
                conversation workflows.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                For example, let’s select ‘Time Off’.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Once you click it, you are directed to a page to download your
                BambooHR conversation automation flow.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Select YES to download your conversation, which you can find in
                the draft section.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, click OKAY.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, under the draft section, you can find your TIME OFF
                conversation template.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The warning sign indicates that no connection has been set up.
                You need to set it up.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click on No Trigger. After that, you can find a window which
                asks you to edit your app workflow so that users can receive
                pre-populated triggers from chatbots or conditions as you set
                them for ongoing conversations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click on Save to proceed further.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, click on Assign TimeOff Policy. Fill details in the edit
                action card and connect account.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can see that all fields are provided with specific details
                beforehand. Click on Save.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It's time to activate the workflow. Click on the button on
                extreme top right corner.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Click Yes to activate the workflow.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, let’s go back to the HR bot. It’s in the bot section. Find
                your dialog.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Find your conversation template ‘Apply Leave’ and click on it to
                find conversation flow for this. .
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Now, select call automation dialog.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Fill all the details collected from the user and click save.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you return to your chat dialog, you will find no warning
                signs, and your dialog is ready to be used.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To test whether your HR chatbot works properly, click TRY ME at
                the bottom corner.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Provide details as your HR chatbot asks you to see the
                effectiveness of your chatbot development.
              </p>
              <h3 className="font-section-sub-header-small">
                Publish your HR chatbot{" "}
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Once everything works all right, you can publish your bot to
                your favorite channel.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                It can be MS Teams, Slack, or Web Widget.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To learn more about how you can build your HR virtual assistant,
                watch the demo{" "}
                <a href="https://www.loom.com/share/69651577ab9c44889004b01f1ea875e3?sid=4b1338ed-55e4-4064-87a7-94b40787ac97">
                  here.
                </a>
              </p>
            </div>

            <NocodeWrapper />
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Why Workativ for your HR virtual assistant?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ provides users immense flexibility to leverage the
                business benefits by enabling them to build an easy yet powerful
                virtual assistant for human resource teams.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Omni channel accessibility
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ can easily fit Teams, Slack, or Web architecture to
                deliver omnichannel convenience to your users. So, it is easy
                and fast for your users to adopt and use the chatbot in no time.
              </p>

              <h3 className="font-section-sub-header-small-home">
                NLP and AI algorithms
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Get extended customization flexibility with a chatbot builder
                that allows you to create simple to complex dialogs and
                conversations. Built on top of the NLP engine, the bot
                simplifies user intent and understanding.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Drag-and-drop intuitive interface
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                The no-code platform is a simple drag-and-drop interface. All
                you need is sign up, choose your templates, integrate with apps,
                and create app workflows.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Easy to deploy
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ offers multiple platforms to create your virtual HR
                assistant. Choose from three of the options,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonial">
                  App integration
                </li>
                <li className="font-section-normal-text-testimonials">
                  On-prem connect
                </li>
                <li className="font-section-normal-text-testimonials">
                  Marketplace download
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A virtual assistant for HR is essentially a powerful tool as the
                work culture evolves massively everywhere. Human resources need
                automation to tackle uncertain situations and stay resilient. As
                a result, your HR team will become more empowered to minimize
                workplace threats such as attrition, rising costs, and churn.
                Furthermore, a virtual HR assistant can help fetch AI-based
                analytics to gauge trends and prepare for uncertainties. In
                addition, AI innovations like generative AI and the evolution of
                ChatGPT offer more ways to reshape your workplace ecosystem and
                enhance employee experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To stay compliant and bring a change to your traditional HR
                model, you can evaluate your current situation and consider the
                benefits that a virtual assistant for HR offers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                {" "}
                Learn more about how Workativ helps you automate repetitive work
                processes by connecting with our expert.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What can you automate with an HR virtual assistant?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR virtual chatbot can automate various HR processes such as,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Onboarding
                </li>
                <li className="font-section-normal-text-testimonials">Leave</li>
                <li className="font-section-normal-text-testimonials">
                  Expense management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Scheduling appointments
                </li>
                <li className="font-section-normal-text-testimonials">
                  Answering FAQs etc
                </li>
              </ul>
              <h3 className="font-section-sub-header-small">
                2. How can you benefit from an HR virtual assistant?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can automate and streamline routine tasks for your employees
                and improve their efficiency and productivity by enabling them
                to auto-resolve their problems through self-service and
                enhancing access to information.
              </p>

              <h3 className="font-section-sub-header-small">
                3. How can you ensure data security and privacy with your HR
                chatbots?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Data privacy and security are paramount to making optimal use of
                AI-powered chatbots. While you build your chatbot, ensure you
                use data free from misinformation or bias. With that, you can
                also ensure using a chatbot builder that provides relevant data
                protection regulations and offers robust security measures. It's
                essential to protect sensitive employee information.
              </p>

              <h3 className="font-section-sub-header-small">
                4. Is it a massive effort to build your HR virtual assistant?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In-house chatbot development is always a headache. But, if you
                choose to leverage a no-code platform with a robust chatbot
                builder framework, you can build it seamlessly without having
                too much technical knowledge. Workativ provides a robust
                platform that enables you to create an HR chatbot. You can
                follow this{" "}
                <a href="https://www.loom.com/share/69651577ab9c44889004b01f1ea875e3?sid=4b1338ed-55e4-4064-87a7-94b40787ac97">
                  tutorial
                </a>{" "}
                to build your first free chatbot for HR processes.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}

const NocodeWrapper = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`nocode_wrapper_upd nocode_wrapper_green ${
        isMobile ? "mb-4" : "mb-5"
      }`}
    >
      {isMobile ? (
        <h4 className="font-section-sub-header-small-home mb-0 text-align-center">
          How to build HR Chatbot in 10 mins
        </h4>
      ) : (
        <h4 className="font-section-sub-header-small-home mb-0">
          How to build HR Chatbot in 10 mins
        </h4>
      )}

      <button
        className="font-section-normal-text-testimonials-medium cursor-pointer color-white ml-40"
        type="button"
        onClick={() =>
          (window.location.href =
            "https://www.youtube.com/watch?v=ymkNx2zMLAE&t=185s")
        }
      >
        {" "}
        WATCH NOW
      </button>
    </div>
  );
};
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              HR DIGITAL TRANSFORMATION: The ultimate guide - 2023
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              HR guide to Conversational AI - How HR Chatbot Solve Remote work
              Challenges
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide"
              className="font-section-normal-text-testimonials"
            >
              HR HELP DESK: The ultimate guide - 2023
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
